<script lang="ts" setup>
const { y } = useScroll(document);
const isVisible = computed(() => y.value > 500);

function scrollToTop() {
  document.body.scrollIntoView({ behavior: "smooth" });
}
</script>

<template>
  <UButton
    variant="outline"
    color="primary"
    size="md"
    class="fixed right-5 z-10 cursor-pointer transition-all duration-[400ms]"
    :class="[isVisible ? 'bottom-24' : '-bottom-14 ']"
    @click="scrollToTop"
  >
    <i class="pe-7s-angle-up pe-2x" />
  </UButton>
</template>

<style scoped>

</style>
