<script setup lang="ts">
import type {
  IconLinkRecord,
  LinkColumnRecord,
  NewsletterSubscriptionRecord,
  PaymentInfoRecord,
} from "~/graphql/datocms/generated";

const { user } = useUserSession();
const layout = await useLayout();
const cartId = await useCartId();

const envName = useRuntimeConfig().public.VERCEL_ENV === "production" ? "" : useRuntimeConfig().public.VERCEL_ENV;
const { copy, isSupported } = useClipboard({ source: () => cartId.value || "" });

function useCopyToClipboard() {
  if (isSupported) {
    copy();
    useSuccessToast("OK", "Cart ID copied to clipboard");
  }
}
</script>

<template>
  <footer>
    <FooterNewsletterSubscription :data="layout?.newsletterSubscription as NewsletterSubscriptionRecord" />

    <div class="bg-gray-600 py-12 text-sm text-white">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-4">
          <div>
            <FooterPaymentInfo :data="layout?.paymentInfo as PaymentInfoRecord" />
            <FooterSocialMedia :data="layout?.socialMedia as Array<IconLinkRecord>" />
          </div>

          <div v-for="linkColumn in layout?.linkColumns" :key="linkColumn.id">
            <LinkColumn :data="linkColumn as LinkColumnRecord">
              <template #title>
                <h3 class="mb-3">
                  {{ linkColumn.title }}
                </h3>
              </template>
              <template #item="{ link }">
                <LinkCustom class="block py-2" :data="link" />
              </template>
            </LinkColumn>
          </div>
        </div>
      </div>
    </div>

    <FooterBottomBar />
    <FooterScrollToTop />

    <div v-if="envName" class="fixed bottom-0 flex w-full items-end justify-between p-1">
      <div>
        <span class="cursor-default opacity-50 hover:opacity-100">
          <ResponsiveSizeBadge />
        </span>

        <UBadge v-if="envName" color="indigo" variant="subtle" class="cursor-pointer opacity-50 hover:opacity-100">
          {{ envName.toUpperCase() }}
        </UBadge>
      </div>
      <div class="flex gap-1">
        <ClientOnly>
          <!--
            client only
            otherwise we have hydration mismatch
            not fetching id on server because of auth bug when deployed on vercel
          -->
          <UBadge color="indigo" variant="subtle" class="cursor-default opacity-50 hover:opacity-100">
            USERCLASS:&nbsp;{{ user?.userClass || "no user class" }}
          </UBadge>
          <UBadge color="indigo" variant="subtle" class="cursor-default opacity-50 hover:opacity-100">
            CART:&nbsp;<span @click="useCopyToClipboard">{{ cartId || "no current cart" }}</span>
          </UBadge>
        </ClientOnly>
      </div>
    </div>
  </footer>
</template>

<style scoped>

</style>
