<script lang="ts" setup>
import { debounce } from "perfect-debounce";
import type { FormState } from "~/models/forms";
import type { ProductListItem } from "~/models/products";

const { trackCustomEvent } = useGtmTracking();
const { locale, t } = useI18n();
const localePath = useLocalePath();
const route = useRoute();

const { setParamsAndExecute, setParamsAndNavigate } = await useCatalog();
const mobileSearch = useMobileSearch();
const searchTerm = useSearchTerm();

const desktopSearchSuggestions = ref(null);
const isOpenDesktopSearchSuggestions = ref(false);
onClickOutside(desktopSearchSuggestions, _ => isOpenDesktopSearchSuggestions.value = false);

const searchSuggestionState = ref<FormState>("idle");
const searchSuggestionList = ref<Array<ProductListItem>>([]);
const searchSuggestionCount = ref(0);
const queryLength = computed(() => searchTerm.value.length);

async function fetchData() {
  try {
    searchSuggestionList.value = [] as Array<ProductListItem>;
    isOpenDesktopSearchSuggestions.value = false;

    if (queryLength.value < 3) {
      return;
    }

    searchSuggestionState.value = "pending";

    const data = await $fetch("/api/ecom/search-suggestion", {
      query: { term: searchTerm.value },
    });

    if (data) {
      isOpenDesktopSearchSuggestions.value = true;
      searchSuggestionCount.value = data.productCount;
      searchSuggestionList.value = data.products;

      trackCustomEvent("search-suggestion-fetch");
    }
  }
  catch (error) {
    console.error(error);

    useFailToast(
      t("common.title.error"),
      t("header.search.message.error"),
    );
  }
  finally {
    searchSuggestionState.value = "idle";
  }
}

const debouncedFetchData = debounce(fetchData, 200);

watch(() => searchTerm.value, () => {
  if (searchTerm?.value) {
    debouncedFetchData();
  }
});

function onFocus() {
  if (searchTerm?.value) {
    fetchData();
  }
}

async function onSelectProduct(searchSuggestion: ProductListItem) {
  isOpenDesktopSearchSuggestions.value = false;
  mobileSearch.value.isOpen = false;

  trackCustomEvent("search-suggestion-select");
  await navigateTo(localePath(`${searchSuggestion.clusterUrl}`));
}

async function onSubmit() {
  isOpenDesktopSearchSuggestions.value = false;
  mobileSearch.value.isOpen = false;

  // "/catalog" = main catalog route
  // "/catalog/" = sub category route
  // "/product" = product detail route
  if (route.path.includes("/catalog") && !route.path.includes("/catalog/") && !route.path.includes("/product")) {
    await setParamsAndExecute({ categoryId: undefined, term: searchTerm.value });
  }
  else {
    await setParamsAndNavigate({ term: searchTerm.value });
  }
}
</script>

<template>
  <div class="relative flex flex-col gap-4">
    <form id="search-suggestions-form" @submit.prevent="onSubmit">
      <label for="search-term" class="sr-only">{{ $t("header.search.label.search") }}</label>

      <UButtonGroup size="xl" orientation="horizontal" class="button-group--full-width">
        <UInput
          id="search-term" v-model="searchTerm" type="search" class="input--text-sm"
          :placeholder="t('header.search.placeholder.input')"
          :loading="searchSuggestionState === 'pending'" trailing
          aria-autocomplete="both" aria-owns="search-suggestions"
          :aria-expanded="isOpenDesktopSearchSuggestions"
          data-1p-ignore
          @focus="onFocus"
        />

        <UButton type="submit" variant="solid" color="black" size="xl">
          <i class="pe-7s-search" />
          <span>{{ $t("header.search.label.submit") }}</span>
        </UButton>
      </UButtonGroup>
    </form>

    <div class="lg:hidden">
      <div id="search-suggestions" class="flex flex-col gap-4" role="listbox">
        <div v-for="searchSuggestion in searchSuggestionList" :key="searchSuggestion.id" role="option">
          <ProductCard :product="searchSuggestion" :locale="locale" @select="onSelectProduct" />
        </div>
      </div>

      <div class="sticky bottom-0 bg-white py-3">
        <UButton
          v-if="searchSuggestionCount > 0"
          type="submit" form="search-suggestions-form"
          variant="solid" color="primary" size="lg" class="w-full"
        >
          {{ $t("header.search.label.view-all") }} ({{ searchSuggestionCount }})
        </UButton>
      </div>
    </div>

    <div
      v-show="isOpenDesktopSearchSuggestions"
      ref="desktopSearchSuggestions"
      class="absolute top-[45px] z-10 hidden max-h-[75dvh] w-full overflow-y-auto bg-white shadow-lg lg:block"
      role="listbox"
    >
      <div id="search-suggestions" class="flex flex-col gap-4 p-3" role="listbox">
        <div v-for="searchSuggestion in searchSuggestionList" :key="searchSuggestion.id" role="option">
          <ProductCard :product="searchSuggestion" :locale="locale" @select="onSelectProduct" />
        </div>
      </div>

      <div class="sticky bottom-0 bg-white p-3">
        <UButton
          v-if="searchSuggestionCount > 0"
          type="submit" form="search-suggestions-form"
          variant="solid" color="primary" size="lg" class="w-full"
        >
          {{ $t("header.search.label.view-all") }} ({{ searchSuggestionCount }})
        </UButton>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
