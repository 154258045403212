<script lang="ts" setup>
import type { Custom_CartFragment_Cart_items_CartMainItem as CartMainItem } from "~/graphql/propeller/generated";

const { locale } = useI18n();
const localePath = useLocalePath();

const cart = useCart();
const miniCart = useMiniCart();
const { trackViewCart } = useGtmTracking();

const isPending = ref(true);
const products = computed(() => cart.value?.products || []);

const { refreshCart } = await useCartLoader({ immediate: false });

function getProduct(item: CartMainItem) {
  return products.value.find(p => p.id === item.productId)!;
}

async function fetchData() {
  if (cart.value.cartId) {
    isPending.value = false;
    return;
  }

  isPending.value = true;
  await refreshCart();
  isPending.value = false;
}

watch(() => miniCart.value.isOpen, async () => {
  if (miniCart.value.isOpen) {
    trackViewCart(products.value, cart.value?.productsTotalGross);
    fetchData();
  }
  else {
    miniCart.value.notification = ""; // hides notification
  }
});

const cartItems = computed(() => {
  // slice takes a copy before reversing it, otherwise, the original will be reversed
  // the reason for reversing is to show the most recent addition on top
  return cart.value.items?.slice().reverse();
});

const cartItemCount = computed(() => {
  if (isPending.value) {
    return "";
  }

  return cart.value?.items?.length || 0;
});

async function goToCheckout() {
  miniCart.value.isOpen = false;
  await navigateTo(localePath(`/checkout`));
}

onMounted(async () => {
  // initial fetch, will load the cart into the store
  fetchData();
});
</script>

<template>
  <UPopover
    v-model:open="miniCart.isOpen"
    mode="hover"
    :popper="{ offsetDistance: 10, placement: 'bottom-end' }"
    :ui="{ wrapper: 'relative inline-flex' }"
  >
    <div v-if="cart" class="flex flex-col items-center lg:flex-row lg:gap-2">
      <UChip :text="cartItemCount" :ui="{ background: 'bg-gray-900' }" size="2xl">
        <i id="mini-cart" class="pe-7s-cart pe-2x pe-va text-primary" />
      </UChip>

      <label class="sr-only text-sm md:not-sr-only" for="mini-cart" style="cursor: inherit;">
        {{ $t("header.actions.label.cart") }}
      </label>
    </div>

    <template #panel>
      <div class="w-[320px]">
        <div class="max-h-[65dvh] overflow-y-auto">
          <FeedbackMessage :message="miniCart.notification" message-type="success" />

          <div class="p-3">
            <div v-if="isPending" class="text-center">
              <Loading />
            </div>

            <span v-if="!isPending && !cartItems?.length" class="text-sm font-bold">
              {{ $t("mini-cart.message.warning.no-products") }}
            </span>

            <div v-if="!isPending && cartItems?.length">
              <div class="grid grid-cols-1">
                <div v-for="item in cartItems" :key="item.id">
                  <CartItemCardMini
                    :cart-line="item"
                    :product="getProduct(item)"
                    :is-tax-exempt="cart.isTaxExempt"
                    :locale="locale"
                  />
                  <hr class="my-4">
                </div>
              </div>

              <div class="flex flex-wrap justify-between">
                <div>
                  <span class="font-bold">{{ $t("mini-cart.label.total-price") }}</span>&nbsp;
                  <span v-if="!cart.isTaxExempt" class="text-sm">({{ $t("mini-cart.label.vat-included") }})</span>
                  <span v-if="cart.isTaxExempt" class="text-sm">({{ $t("mini-cart.label.vat-excluded") }})</span>
                </div>

                {{ formatCurrency(cart.productsTotal) }}
              </div>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 bg-white p-3">
          <div class="flex flex-col gap-2">
            <UButton variant="solid" color="black" size="lg" block @click="miniCart.isOpen = false">
              {{ $t("mini-cart.action.continue-shopping") }}
            </UButton>

            <UButton variant="solid" color="black" size="lg" block @click="goToCheckout">
              {{ $t("mini-cart.action.go-to-checkout") }}
            </UButton>
          </div>
        </div>
      </div>
    </template>
  </UPopover>
</template>

<style scoped>

</style>
