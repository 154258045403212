<script setup lang="ts">
import type { IconLinkRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: Array<IconLinkRecord> }>();

const localePath = useLocalePath();
const links = computed(() => props.data.map(l => getLinkForType(l, localePath)));
</script>

<template>
  <div class="hidden bg-gray-600 py-2 text-xs text-white lg:block">
    <div class="container mx-auto">
      <div class="flex flex-wrap items-center justify-between gap-4">
        <div v-for="link in links" :key="link.id">
          <LinkIcon :data="link" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
