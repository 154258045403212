<script lang="ts" setup>
import type { NewsletterRecord } from "~/graphql/datocms/generated";

defineProps<{ data: NewsletterRecord }>();

useHead({
  script: [{
    src: "https://js.createsend1.com/javascript/copypastesubscribeformlogic.js",
    async: true,
  }],
});
</script>

<template>
  <form
    id="newsletter-subscription-form"
    action="https://www.createsend.com/t/subscribeerror?description=" method="post" class="js-cm-form" role="form"
    data-id="5B5E7037DA78A748374AD499497E309EDD26D9DDB1592BBD8B891DDCD63FFB4702B7D8ED5080841F3A27F5EEEAF56E9D86BFB12938F80FE1457BA29EE5C99FAF"
  >
    <div class="border-primary flex items-center gap-2 rounded-lg border-2 bg-white p-0.5">
      <label for="newsletter-subscription-email" class="sr-only">
        {{ $t("newsletter-subscription.form.label.email") }}
      </label>

      <UInput
        id="newsletter-subscription-email" name="cm-ojkuug-ojkuug" :placeholder="data.inputPlaceholder!"
        variant="none" size="lg" class="js-cm-email-input qa-input-email w-full" data-1p-ignore
        type="email" autocomplete="email" inputmode="email" spellcheck="false" maxlength="200" required
      />

      <UButton type="submit" variant="solid" color="primary" size="lg">
        {{ data.buttonText }}
      </UButton>
    </div>
  </form>
</template>

<style scoped>

</style>
