<script lang="ts" setup>
import type { IconLinkRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: Array<IconLinkRecord> }>();

const localePath = useLocalePath();
const links = computed(() => props.data.map(l => getLinkForType(l, localePath)));
</script>

<template>
  <div>
    <div class="flex flex-col gap-2">
      <div v-for="link in links" :key="link.id">
        <LinkIcon :data="link" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
