<script lang="ts" setup>
import { CategorySlugList } from "~/models/categories";

const props = defineProps<{ data: Array<any>; navItems: Array<any> }>();

const localePath = useLocalePath();
const mobileMenu = useMobileMenu();
const route = useRoute();

const { setParamsAndExecute } = await useCatalog();

const currentPath = computed(() => route.path);

const links = computed(() => {
  const links = props.data.map(l => getLinkForType(l, localePath));

  for (const link of links) {
    link.click = async () => {
      if (currentPath.value === link.href) {
        const slug = link.href.split("/").findLast(p => p !== "")!;
        await setParamsAndExecute({ categoryId: CategorySlugList[slug].id, term: undefined, textFilters: undefined });
      }
      else {
        await navigateTo(link.href);
      }

      mobileMenu.value.isOpen = false;
    };
  }

  return links;
});

const { t } = useI18n();

const tabItems = [{
  slot: "menu",
  label: t("header.actions.label.menu"),
}, {
  slot: "account",
  label: t("header.actions.label.account"),
}];
</script>

<template>
  <UTabs :items="tabItems" class="w-full">
    <template #menu>
      <nav>
        <ul>
          <li v-for="(link, index) in links" :key="link.id" :class="{ 'border-1 border-b': index < links.length - 1 }">
            <div v-if="!link!.subLinks">
              <LinkCustom class="block py-2" :data="link" />
            </div>

            <CollapsibleContent v-if="link!.subLinks" :show="{ toggle: true }">
              <template #title>
                <div class="block py-2">
                  {{ link.label }}
                </div>
              </template>
              <template #content>
                <div class="px-3">
                  <ul>
                    <li
                      v-for="(subLink, subIndex) in link!.subLinks"
                      :key="subLink.at(0)!.id"
                      :class="{ 'border-1 border-b': subIndex < link!.subLinks.length - 1 }"
                    >
                      <LinkCustom class="block py-2" :data="subLink.at(0)!" />
                    </li>
                  </ul>
                </div>
              </template>
            </CollapsibleContent>
          </li>
        </ul>
      </nav>
    </template>

    <template #account>
      <nav>
        <ul>
          <li v-for="item in navItems" :key="item.label" class="border-1 border-b last:border-0">
            <NuxtLinkLocale :to="item.href" class="hover:text-primary block py-2" @click="item.click">
              {{ item.label }}
            </NuxtLinkLocale>
          </li>
        </ul>
      </nav>
    </template>
  </UTabs>
</template>

<style scoped>

</style>
