<script lang="ts" setup>

</script>

<template>
  <div class="bg-gray-50 py-3 text-sm">
    <div class="container mx-auto">
      <div class="flex flex-wrap items-center justify-center gap-4 lg:justify-between">
        <div>
          {{ $t("footer.bottom-bar.copyright") }}
        </div>

        <div>
          <a href="https://esc.be/e-business/e-commerce-webshops" target="_blank" class="hover:text-primary underline">
            E-business by ESC
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
