<script lang="ts" setup>
import type { LinkItem } from "~/models/links";

defineProps<{ data: LinkItem }>();

function isSvgIcon(iconName?: string | null): boolean {
  return (iconName === "facebook" || iconName === "instagram");
}
</script>

<template>
  <div class="flex items-center">
    <i v-if="!isSvgIcon(data.iconName)" :class="`pe-7s-${data.iconName}`" class="pe-lg pe-va me-[10px]" />
    <img v-if="isSvgIcon(data.iconName)" :src="`/icons/${data.iconName}.svg`" alt="" class="me-[10px] size-[15px]">

    <span v-if="!data.href">{{ data.label }}</span>

    <NuxtLink v-if="data.href" :to="data.href" :target="data.target" class="hover:text-primary">
      {{ data.label }}
    </NuxtLink>
  </div>
</template>

<style scoped>

</style>
