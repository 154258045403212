<script lang="ts" setup>

</script>

<template>
  <div>
    <NuxtLinkLocale to="/test/developers">
      <div class="sm:hidden">
        <UBadge variant="subtle" color="pink">
          XS
        </UBadge>
      </div>
      <div class="hidden sm:block md:hidden">
        <UBadge variant="subtle" color="pink">
          SM
        </UBadge>
      </div>
      <div class="hidden md:block lg:hidden">
        <UBadge variant="subtle" color="pink">
          MD
        </UBadge>
      </div>
      <div class="hidden lg:block xl:hidden">
        <UBadge variant="subtle" color="pink">
          LG
        </UBadge>
      </div>
      <div class="hidden xl:block">
        <UBadge variant="subtle" color="pink">
          XL
        </UBadge>
      </div>
    </NuxtLinkLocale>
  </div>
</template>

<style scoped>

</style>
