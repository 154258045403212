<script lang="ts" setup>
import { getLinkForType } from "~/utils/links";

const props = defineProps<{ left: Array<any>; right: Array<any> }>();

const localePath = useLocalePath();
const leftLinks = computed(() => props.left.map(l => getLinkForType(l, localePath)));
const rightLinks = computed(() => props.right.map(l => getLinkForType(l, localePath)));
</script>

<template>
  <div class="bg-gray-600 text-[8px] uppercase text-white lg:bg-transparent lg:text-xs lg:text-gray-400">
    <div class="container mx-auto">
      <nav>
        <div class="flex items-center justify-center lg:justify-between">
          <ul class="flex list-none gap-4 lg:gap-8">
            <li v-for="leftLink in leftLinks" :key="leftLink?.id" class="leading-4">
              <LinkCustom class="block py-2" :data="leftLink" />
            </li>
          </ul>

          <ul class="hidden list-none gap-8 text-xs uppercase text-gray-400 lg:flex">
            <li v-for="rightLink in rightLinks" :key="rightLink?.id" class="leading-4">
              <LinkCustom class="block py-2" :data="rightLink" />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<style scoped>

</style>
