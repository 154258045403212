<script setup lang="ts">
import type { IconLinkRecord } from "~/graphql/datocms/generated";

const layout = await useLayout();
const mobileMenu = useMobileMenu();
const mobileSearch = useMobileSearch();

const localePath = useLocalePath();
const { t } = useI18n();

const {
  loggedIn,
  clear,
} = useUserSession();

const authItems = [
  {
    label: t("header.actions.label.dashboard"),
    href: localePath("/account/dashboard"),
    click: () => { mobileMenu.value.isOpen = false; },
  },
  // F2
  // // [{
  // //   label: t("header.actions.label.wish-list"),
  // //   href: "/TODO/nl/wishlist",
  // // }],
  {
    label: t("auth.label.sign-out"),
    click: async () => {
      await clear();
      await navigateTo(localePath(`/`), { external: true });
    },
  },
];

const anonItems = [
  {
    label: t("header.actions.label.authentication"),
    href: localePath("/auth/login"),
    click: () => { mobileMenu.value.isOpen = false; },
  },
  {
    label: t("header.actions.label.registration"),
    href: localePath("/auth/registration"),
    click: () => { mobileMenu.value.isOpen = false; },
  },
];

const accountNavItems = computed(() => {
  if (loggedIn.value) {
    return authItems;
  }

  return anonItems;
});
</script>

<template>
  <header class="fixed z-20 w-full bg-white drop-shadow-sm lg:drop-shadow-md">
    <DatoCmsPreviewBanner />
    <HeaderTopBar :data="(layout!.topBar as Array<IconLinkRecord>)" />
    <HeaderTopMenu :left="layout!.topMenuLeft" :right="layout!.topMenuRight" />

    <div class="container mx-auto">
      <div class="flex items-center justify-between gap-6 py-3 lg:gap-16 lg:pb-3 lg:pt-0">
        <div class="flex cursor-pointer flex-col items-center lg:hidden">
          <i id="open-mobile-menu" class="pe-7s-menu pe-2x pe-va" role="button" @click="mobileMenu.isOpen = true" />
          <label
            class="sr-only text-sm md:not-sr-only"
            for="open-mobile-menu"
          >
            {{ $t("header.actions.label.menu") }}
          </label>
        </div>

        <div class="w-[225px] lg:h-[90px]">
          <NuxtLinkLocale to="/">
            <img src="/logo/wijnen-de-clerck.png" alt="Wijnen De Clerck" class="w-full">
          </NuxtLinkLocale>
        </div>

        <div class="hidden flex-1 lg:block">
          <HeaderSearch />
        </div>

        <div>
          <HeaderActions :nav-items="accountNavItems" />
        </div>
      </div>
    </div>

    <HeaderMainMenu :data="layout!.mainMenu" />

    <LazyUSlideover v-model="mobileMenu.isOpen" side="left" prevent-close>
      <div class="flex flex-1 overflow-y-auto">
        <div class="size-full px-3">
          <div class="my-3 flex items-center justify-end">
            <label class="sr-only" for="close-mobile-menu">{{ $t("common.action.close") }}</label>
            <i
              id="close-mobile-menu"
              class="pe-7s-close pe-3x pe-va cursor-pointer"
              role="button"
              @click="mobileMenu.isOpen = false"
            />
          </div>

          <HeaderMobileMenu :data="layout!.mainMenu" :nav-items="accountNavItems" />
        </div>
      </div>
    </LazyUSlideover>

    <LazyUSlideover v-model="mobileSearch.isOpen" side="right" prevent-close>
      <div class="flex flex-1 overflow-y-auto">
        <div class="size-full px-3">
          <div class="my-3 flex items-center justify-end">
            <label class="sr-only" for="close-mobile-search">{{ $t("common.action.close") }}</label>
            <i
              id="close-mobile-search"
              class="pe-7s-close pe-3x pe-va cursor-pointer"
              role="button"
              @click="mobileSearch.isOpen = false"
            />
          </div>

          <HeaderSearch />
        </div>
      </div>
    </LazyUSlideover>
  </header>
</template>

<style scoped>

</style>
