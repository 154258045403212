<script lang="ts" setup>
import type { ProductListItem } from "~/models/products";

const { cartLine, product, isTaxExempt } = defineProps<{
  cartLine: CustomCartLine;
  product: ProductListItem;
  isTaxExempt: boolean;
  locale: string;
}>();

const hasPromotionPrice = computed(() => {
  return cartLine?.price !== product?.priceUser && !isTaxExempt;
});
</script>

<template>
  <div class="relative bg-white">
    <div class="grid grid-cols-3 gap-4">
      <div class="col-span-1">
        <img
          :src="product.image?.url ?? '/logo/placeholder.svg'"
          :alt="product.clusterName"
          class="mx-auto max-h-[150px] object-center"
          loading="lazy"
          onerror="this.onerror=null; this.src='/logo/placeholder.svg';"
        >
      </div>

      <div class="col-span-2 flex flex-col gap-4">
        <div class="flex flex-col gap-1">
          <span class="font-bold">
            {{ product.clusterName }}
          </span>

          <div class="flex flex-wrap gap-x-2">
            <span>{{ formatCurrency(cartLine.price) }}</span>
            <span v-if="hasPromotionPrice" class="text-gray-300 line-through">
              {{ formatCurrency(product.priceUser) }}
            </span>
          </div>
        </div>

        <div>
          <span class="text-sm">{{ $t("cart-item.label.quantity") }}: {{ cartLine.quantity }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
