<script lang="ts" setup>
import { DRAFT_COOKIE_NAME } from "@esc-bv/ebs-nuxt-datocms-preview/utils/cookieSettings";

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});

const isDraft = useCookie(DRAFT_COOKIE_NAME);
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <Link rel="preconnect" href="https://media.helice.cloud" />
      <Link rel="preconnect" href="https://www.datocms-assets.com" />

      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>

      <Link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <Link rel="icon" href="/favicon.ico" sizes="48x48" />
      <Link rel="icon" href="/favicon.svg" sizes="any" type="image/svg+xml" />
      <Link rel="manifest" href="/site.webmanifest" />
      <Meta name="theme-color" content="#CC9F26" media="(prefers-color-scheme: light)" />
      <Meta name="theme-color" content="#CC9F26" media="(prefers-color-scheme: dark)" />

      <!-- Disallows manual or browser zoom (for font sizes less that 15px, iOS Safari automatically zooms in
           on a webpage when focussing an input field but does not "unzoom" the webpage after blur) -->
      <Meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />

      <Link rel="stylesheet" href="https://use.typekit.net/ndh5tbk.css" />

      <Link rel="stylesheet" href="/pe-icon-7-stroke/css/pe-icon-7-stroke.css" />
      <Link rel="stylesheet" href="/pe-icon-7-stroke/css/helper.css" />

      <Link rel="stylesheet" href="/pe-icon-7-filled/css/pe-icon-7-filled.css" />
      <Link rel="stylesheet" href="/pe-icon-7-filled/css/helper.css" />
    </Head>
    <Body>
      <HeaderBase />

      <main :class="[isDraft ? 'mt-[160px] md:mt-[165px] lg:mt-[235px]' : 'mt-[145px] md:mt-[150px] lg:mt-[220px]']">
        <div class="container mx-auto">
          <slot />
        </div>
      </main>

      <FooterBase />

      <ModalAgeVerification />
    </Body>
  </Html>
</template>

<style></style>
