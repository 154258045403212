<script lang="ts" setup>
const props = defineProps<{ navItems: Array<any> }>();

const { loggedIn } = useUserSession();

const dropDownItems = computed(() => {
  return props.navItems.map(item => ([item]));
});

const mobileSearch = useMobileSearch();
</script>

<template>
  <div class="flex items-center gap-4 md:gap-8">
    <div class="flex cursor-pointer flex-col items-center lg:hidden">
      <i
        id="open-mobile-search"
        class="pe-7s-search pe-2x pe-va text-primary"
        role="button"
        @click="mobileSearch.isOpen = true"
      />
      <label
        class="sr-only text-sm md:not-sr-only"
        for="open-mobile-search"
      >
        {{ $t("header.search.label.submit") }}
      </label>
    </div>

    <div class="flex items-center">
      <UDropdown :items="dropDownItems" mode="hover" :popper="{ placement: 'bottom-start' }">
        <div class="flex flex-col items-center lg:flex-row lg:gap-2">
          <UChip :ui="{ background: 'bg-green-500' }" :show="loggedIn">
            <i id="account-menu" class="pe-7s-user pe-2x pe-va text-primary" />
          </UChip>

          <label
            class="sr-only text-sm md:not-sr-only"
            for="account-menu"
          >
            {{ $t("header.actions.label.account") }}
          </label>
        </div>
      </UDropdown>
    </div>

    <HeaderMiniCart />
  </div>
</template>

<style scoped>

</style>
