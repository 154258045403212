<script lang="ts" setup>
const currentDate = new Date();
const expirationDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
const ageVerificationCookie = useCookie<boolean>("_wdc_age-verification", {
  expires: expirationDate,
  sameSite: "lax",
  secure: true,
});

const isVisible = ref(false);

function verification() {
  ageVerificationCookie.value = true;
  isVisible.value = false;
}

onMounted(() => {
  isVisible.value = ageVerificationCookie.value === undefined;
});
</script>

<template>
  <UModal v-model="isVisible" prevent-close>
    <div class="relative p-8">
      <h2 class="mb-5 text-center">
        {{ $t("age-verification.modal.text") }}
      </h2>

      <div class="grid grid-cols-1 gap-4">
        <UButton variant="solid" color="primary" size="md" class="w-full" @click="verification">
          {{ $t("age-verification.modal.action.yes") }}
        </UButton>
        <UButton variant="outline" color="primary" size="md" class="w-full" to="https://www.google.be">
          {{ $t("age-verification.modal.action.no") }}
        </UButton>
      </div>
    </div>
  </UModal>
</template>

<style scoped>

</style>
